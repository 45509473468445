<template>
  <div id="dialogSettingRawMaterial">
    <v-dialog v-model="dialog" max-width="1000" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ dialogTitle }}</span
          ><v-divider class="mx-4" inset vertical></v-divider>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" v-if="isEdit || isViewer">
                <v-text-field
                  v-model="item.bom_id"
                  label="รหัส"
                  dense
                  required
                  readonly
                  ref="bom_id"
                ></v-text-field>
              </v-col>
              </v-row>
              <v-row>
              <v-col cols="12" sm="12">
                  <v-text-field
                  v-model="item.bom_name"
                  label="ชื่อสูตร"
                  type="string"
                  dense
                  required
                  ref="qty"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="6">
                <v-autocomplete
                  v-model="item.bom_type_id"
                  :items="bom_type_list"
                  item-value="bom_type_id"
                  item-text="bom_type_name"
                  :return-object="false"
                  label="ประเภทสูตร"
                  dense
                  ref="bom_type"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" sm="6">
                <v-autocomplete
                  v-model="item.material_id"
                  :items="material_show"
                  item-value="material_id"
                  item-text="material_name"
                  :return-object="false"
                  label="วัตถุดิบที่สร้าง"
                  dense
                  ref="receive_item"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" v-if="isEdit">
                <v-autocomplete
                  v-model="item.status"
                  :items="enumStatus"
                  item-value="id"
                  item-text="name"
                  :return-object="false"
                  dense
                  label="สถานะ"
                  ref="status"
                ></v-autocomplete>
              </v-col>
              <v-col cols="8" sm="8" v-if="!isViewer">
                <v-autocomplete
                  v-model="material"
                  :items="material_show"
                  item-value="material_id"
                  item-text="material_name"
                  :return-object="false"
                  label="วัตถุดิบ"
                  dense
                  ref="receive_item"
                ></v-autocomplete>
              </v-col>
              <v-col cols="2" sm="2" v-if="!isViewer">
                  <v-text-field
                  v-model="material_qty"
                  label="จำนวน"
                  type="number"
                  dense
                  required
                  ref="qty"
                  v-if="!viewer"
                  @keydown.enter="addReceiveItem()"
                ></v-text-field>
              </v-col>
              <v-col cols="2" sm="2" v-if="!isViewer">
                <v-btn class="primary" @click="addReceiveItem()"> เพิ่ม </v-btn>
              </v-col>
              <v-col cols="12" sm="12"><template>
                <v-data-table
                  :headers="list_data_headers"
                  :items="list_data_item"
                  :items-per-page="5"
                  class="elevation-1"
                >\
                <template v-slot:item.unit_id="{ item }">
                  <v-autocomplete
                    v-model="item.unit_id"
                    :items="unitList"
                    item-value="unit_id"
                    item-text="unit_name"
                    :return-object="false"
                    dense
                    ref="unit"
                    v-if="!viewer"
                    @keydown.enter="NextFocus('description')"
                  ></v-autocomplete>
                  <span v-if="viewer">{{ item.unit_name }}</span>
                </template>
                <template v-slot:item.qty="{ item }">
                  <v-text-field
                  v-model="item.qty"
                  type="string"
                  dense
                  required
                  ref="qty"
                  v-if="!viewer"
                ></v-text-field>
                  <span v-if="viewer">{{ item.qty }}</span>
                </template>
                <template v-slot:item.actions="{ item }" v-if="!isViewer">
                  <v-icon class="mr-3 primary--text" @click="deleteItem(item)">
                    mdi-delete-forever
                  </v-icon>
                </template>
              </v-data-table>
                </template>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary--text" @click="cancel" v-if="!isViewer"> ยกเลิก </v-btn>
          <v-btn class="primary--text" @click="cancel" v-if="isViewer"> ปิด </v-btn>
          <v-btn class="primary" @click="submit" v-if="!isViewer"> บันทึก </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import baseEnum from "@js/enum";
import { mapActions, mapState } from "vuex";

export default {
  name: "DialogSettingRawMaterial",
  props: ["modal", "item", "itemIndex", "itemIndexViewer"],
  data() {
    return {
      enumStatus: baseEnum.status.data,
      dialog: false,
      itemEdit: {},
      indexEdit: -1,
      indexViewer: -1,
      unitList: [],
      material_list: [],
      material_show: [],
      bom_type_list: [],
      material_qty: 0,
      material_add: "",
      material_bom_id: "",
      bom_name: "",
      material: "",
      viewer: true,
      receipe_status: 1,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      // modal: false,
      menu2: false,
      receive_item: "",
      list_data_headers: [
        {
          text: "รายการ",
          value: "material_name",
          divider: true,
          align: "start",
        },
        {
          text: "หน่วย",
          value: "unit_id",
          divider: true,
          width: 100,
          align: "center",
        },
        {
          text: "จำนวน",
          value: "qty",
          divider: true,
          width: 100,
          align: "center",
        },
        {
          text: "",
          value: "actions",
          divider: true,
          width: 50,
          align: "center",
        },
      ],
      list_data_item: []
    };
  },
  mounted() {
    this.getUnitList();
    this.getMaterialList();
    this.getMaterialTypeList();
  },
  computed: {
    ...mapState("auth", ["user"]),
    isEdit() {
      return this.indexEdit == -1 ? false : true;
    },
    isViewer() {
      this.viewerTrigger(this.indexViewer)
      return this.indexViewer == -1 ? false : true;
    },
    dialogTitle() {
      if(this.viewer) return "ข้อมูล"
      return this.indexEdit == -1 ? "เพิ่ม" : "แก้ไข";
    },
  },
  methods: {
    ...mapActions("settings", [
      "actProductCreate",
      "actProductUpdate",
      "actUnitGets",
      "searchMaterial",
      "getMaterialAll",
      "getMaterialGet",
      "productReceipeCreate",
      "productReceipeUpdate",
      "productReceipeTypeGets"
    ]),
    closeModal() {
      this.$emit("close", false);
    },
    cancel() {
      this.closeModal();
      this.indexViewer = -1;
    },
    viewerTrigger(indexViewer){
      this.viewer = indexViewer == -1 ? false : true;
    },
    deleteItem(item){
      console.log(item)
      this.list_data_item = this.list_data_item.filter((row)=>{ return item.material_id !== row.material_id})
      this.itemEdit.items = this.itemEdit.items.filter((row)=>{ return item.material_id !== row.material_id})
    },
    getUnitList() {
      this.actUnitGets().then(({ status, data }) => {
        if (status == 200 && data.status == "success") {
          const listData = data.result.map((row)=>{
            const {UnitId, UnitName, unit_id, unit_name} = row
            const result = {
              ...row,
              unit_id: UnitId ? UnitId : unit_id,
              unit_name: UnitName ? UnitName : unit_name
            }
            return result
          })
          this.unitList = listData
        } else {
          this.Error("เกิดข้อผิดพลาด : " + data.result);
        }
      });
    },
    getMaterialList() {
      this.getMaterialAll().then(({ status, data })=> {
        if (status == 200 && data.status == "success") {
          this.material_list = data.result
          this.material_show = data.result
        } else {
          this.Error("เกิดข้อผิดพลาด : " + data.result);
        }
      })
    },
    getMaterialTypeList() {
      this.productReceipeTypeGets().then(({ status, data })=> {
        if (status == 200 && data.status == "success") {
          this.bom_type_list = data.result
        } else {
          this.Error("เกิดข้อผิดพลาด : " + data.result);
        }
      })
    },
    submit() {

      const body = {
        created_by: this.user.UserId,
        modified_by: this.user.UserId,
        bom_name: this.item.bom_name,
        bom_id: this.item.bom_id || "",
        material_id: this.item.material_id,
        bom_type_id: this.item.bom_type_id,
        items: this.list_data_item,
        status: this.item.status || 1
      }

      console.log(body)
      if (this.indexEdit == -1) {
        //เพิ่ม
        this.productReceipeCreate(body).then(({ status, data }) => {
          if (status == 200 && data.status == "success") {
            this.Success("SUCCESS")
            this.closeModal();
          } else {
            this.Error(data.result);
          }
        });
      } else {
        //แก้ไข
        this.productReceipeUpdate(body).then(({ status, data }) => {
          if (status == 200 && data.status == "success") {
            this.closeModal();
          } else {
            this.Error(data.result);
          }
        });
      }
    },
    async addReceiveItem(){
      if(!this.itemEdit.items) {
        this.itemEdit.items = []
      }
      const {data = {}} = await this.getMaterialGet(this.material)
      const {result = []} = data
      const mterial_detail = result[0]
      const {material_id = "", unit_id = "", material_name = ""} = mterial_detail
      const item_exist = this.itemEdit.items.filter((row) => row.material_id === material_id)
      const material_add = {
        material_name,
        material_id,
        qty: this.material_qty,
        unit_id
      }
      if (item_exist.length > 0) {
        return
      } else {
        mterial_detail.qty = 0
        this.list_data_item.push(material_add)
        this.itemEdit.items.push(material_add)
      }
    },
  },
  watch: {
    modal() {
      this.dialog = this.modal;
    },
    item() {
      this.itemEdit = this.item;
      this.list_data_item = this.item.items ? this.item.items : [];
    },
    itemIndex() {
      this.indexEdit = this.itemIndex;
    },
    itemIndexViewer() {
      this.indexViewer = this.itemIndexViewer;
    },
  },
};
</script>

<style></style>

